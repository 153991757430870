
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import { formatFinancialAmounts, formatTwoDecimal} from '../../utils/format'

export default Vue.extend({
  name: "datasourceAllData",
  data: () => ({
    data: [],
    isLoading: false,
  }),
  computed: {
    yearSelected() {
      return this.$store.getters["ddcreport/getYearSelected"].year;
    },
    title() {
      return i18n.t("reports.ddcreports.listReports.datasourceAllData");
    },
    headers() {
      return [
        {
          text: i18n.t("reports.ddcreports.datasourceAllData.n"),
          value: "n",
          width: "15%",
        },
        {
          text: i18n.t("reports.ddcreports.datasourceAllData.year"),
          value: "surveyYear",
        },
        {
          text: i18n.t("reports.ddcreports.datasourceAllData.region"),
          value: "region",
        },
        {
          text: i18n.t("reports.ddcreports.datasourceAllData.country"),
          value: "country",
        },
        {
          text: i18n.t("reports.ddcreports.datasourceAllData.gni"),
          value: "gni",
        },
        {
          text: i18n.t("reports.ddcreports.datasourceAllData.data_year"),
          value: "surveyYear",
        },
        {
          text: i18n.t("reports.ddcreports.datasourceAllData.ds_Country_Registry"),
          value: "legacy_Ds_Country_Registry",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.ds_HTCs_Registry"),
          value: "ds_HTCs_Registry",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.ds_All_HTCs"),
          value: "legacy_Ds_HTCs_Registry",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.ds_Some_HTCs"),
          value: "legacy_Ds_Some_HTCs",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.ds_Hemo_Society_NMO"),
          value: "legacy_Ds_All_HTCs",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.ds_Hospitals_Registry"),
          value: "dataSource_IsHospitalOrHTC",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.ds_Health_Ministry"),
          value: "dataSource_IsHealhMinistry",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.ds_Other"),
          value: "dataSource_IsOther_Detail",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.update_yearly"),
          value: "databaseUpdateFrequency_IsYearly",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.update_ongoing"),
          value: "databaseUpdateFrequency_IsOngoing",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.update_Other"),
          value: "databaseUpdateFrequency_IsOther_Detail",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.update_by_Doctors"),
          value: "databaseUpdatedBy_IsDoctor",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.update_by_Patients"),
          value: "databaseUpdatedBy_IsPatientOrganization",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.update_by_Hospitals"),
          value: "databaseUpdatedBy_IsHospitalOrClinics",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.update_by_Other"),
          value: "databaseUpdatedBy_IsOther_Detail",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.percent_population"),
          value: "patientsHTCAccessPercentage",
          formatter: (val: number): number | string => formatTwoDecimal(val)
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.percent_pop_not_known"),
          value: "patientsHTCAccessPercentage_IsNotKnown",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.ds_patient_all_included"),
          value: "isAllIdentifiedPatientsIncluded",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.ds_patient_all_included_comments"),
          value: "isAllIdentifiedPatientsIncluded_Detail",
        },{
          text: i18n.t("reports.ddcreports.datasourceAllData.comment"),
          value: "dataSourceComment",
        },
      ];
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      axios
        .get(`Report/DDC/DataSource-AllData`, {
          params: { year: this.yearSelected },
        })
        .then((res) => {
          this.data = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(`Report/DDC/DataSource-AllData/export`, {
          params: { year: this.yearSelected },
          responseType: "blob",
        })
        .then((res) => {
          const fileName =
            `${i18n.t("reports.ddcreports.pagetitle")} -` + this.title;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.yearSelected) {
      this.getData();
    }
  },
  watch: {
    yearSelected() {
      this.getData();
    },
  },
});
